import React from "react"
import { Link } from "gatsby"

import Layout from "../layout/layout"
import Button from "../components/button"
import ImageGroup from "../components/contactImageGroup"

const InfoPage = () => {
  return (
    <Layout
      title="Important Information"
      description="Important Information for students, international, refunds and withdrawls, pastoral care, NZQA assurance"
    >
      <div className="layout-container study-container">
        <div className="info-content">
          <h3 className="info-mobile-heading">Important information</h3>
          <div className="info-container">
            <h6 id="international_students">International students</h6>
            <p className="info-p">
              Information for international students can be found in the:
            </p>
            <br />
            <a
              href="/Summit Institute International Student Information Pack.pdf"
              download
              className="info-link"
            >
              Summit Institute International Student Information Pack
            </a>
          </div>
          <div className="info-container">
            <h6 id="refund_policy">Refunds and withdrawals</h6>
            <p className="info-p">
              Information about refunds and withdrawals can be found in the:
            </p>
            <br />
            <a
              href="/Summit Institute Refunds and Withdrawals Policy.pdf"
              download
              className="info-link"
            >
              Summit Institute Refunds and Withdrawals Policy
            </a>
          </div>
          <div className="info-container">
            <h6 id="pastoral_care">Pastoral care</h6>
            <p className="info-p">
              Summit Institute enjoys a reputation for genuinely caring for, and
              supporting, students. Once you are enrolled with Summit Institute we are
              an email or call away, if you ever need help.
            </p>
            <br />
            <p>
              Information about pastoral care for domestic students can be found
              in:
            </p>
            <br />
            <a
              href="/Summit Institute Pastoral Care of Domestic Students.pdf"
              download
              className="info-link"
            >
              Summit Institute Pastoral Care of Domestic Students
            </a>
            <br />
            <br />
            <p>
              Information about pastoral care for international students can be
              found in the:
            </p>
            <br />
            <a
              href="/Summit Institute International Student Information Pack.pdf"
              download
              className="info-link"
            >
              Summit Institute International Student Information Pack
            </a>
            <br />
            <br />
            <p>
              Information about specialist agencies and helplines can be found
              in:
            </p>
            <br />
            <a
              href="/Summit Institute Physical and Mental Wellbeing of Students - Agencies and Helplines.pdf"
              download
              className="info-link"
            >
              Physical and Mental Wellbeing of Students
            </a>
            <br />
            <br />
            <p>
              Remember too, once you are enrolled with Summit Institute we are an email
              or call away, if you ever need help.
            </p>
          </div>
          <div className="info-container">
            <h6 id="educational_performance">Educational performance</h6>
            <br />
            <p>
              Summit Institute is rated a Category One educational provider by the New
              Zealand Qualifications Authority (NZQA). Category One is the
              highest rating.
            </p>
            <br />
            <b>NZQA quality assurance</b>
            <br />
            <br />
            <p>
              NZQA conducts an audit, known as the External Evaluation and
              Review (EER), on private education establishments every four
              years.
            </p>
            <br />
            <p>
              The EER is a component of NZQA’s evaluative quality assurance
              framework. The review process provides NZQA with an independent
              judgment on the educational performance and capability in
              self-assessment of the PTE under review.
            </p>
            <br />
            <p>
              NZQA has advised that it is again “Highly Confident in Summit Institute’
              educational performance” and “Highly Confident in Summit Institute’
              self-assessment capability” following the latest EER in May 2021.
            </p>
            <br />
            <p>You can access Summit Institute’ latest EER Report here:</p>
            <br />
            <a
              href="/Summit Institute External Evaluation and Review Report.pdf"
              download
              className="info-link"
            >
              Summit Institute External Evaluation and Review Report
            </a>
          </div>
          <div className="info-container">
            <Link to="/privacy" className="info-link">
              <h6 id="privacy_policy">Privacy policy</h6>
            </Link>
            <p className="info-p">
              Information about privacy can be found in the:
            </p>
            <br />
            <Link to="/privacy" className="info-link">
              Summit Institute Privacy Policy
            </Link>
          </div>
          <div className="info-container">
            <Link to="/terms" className="info-link">
              <h6 id="terms_of_use">Terms of use</h6>
            </Link>
            <p className="info-p">
              Information about privacy can be found in the:
            </p>
            <br />
            <Link to="/terms" className="info-link">
              Summit Institute Terms
            </Link>
          </div>
          <div className="hide-mobile">
            <Link to="/contact">
              <Button>Contact us</Button>
            </Link>
          </div>

          <ImageGroup className="only-mobile info-mobile-images"></ImageGroup>
        </div>
        <ImageGroup className="hide-mobile"></ImageGroup>
        <div className="only-mobile">
          <Link to="/contact">
            <Button>Contact us</Button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default InfoPage
